import {insertAfter, insertBefore} from '../../lib/scripts/utilities';

export default function( window, pagePostType = '' ) {
  if ( window.ads ) {
    let mylazyQueue = window.mylazyQueue || [];
    let adQueue = window.adQueue || [];
    let recommendationFeedAnchor = '#main_content .tags';

    // OutOfPage
    window.adlib.getLog( ' OutOfPage ', 'blue' );
    insertBefore( '#body-wrapper', '<div id="OutOfPage" style="position: absolute;"></div>' );

    setTimeout(function() {
      window.adlib.getLog('OutOfPage - setTimeout 500', 'green');
      window.adQueue.push( { type: 'adCall', data: { adSlot: 'OutOfPage' } } );
    }, 500);

    if ( window.MobileMinWidth.matches === true ) {

      // window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos1'}} );
      // <div id="Wrapper_Mobile_Pos2" style="min-height:250px; background:#f3f3f3;"></div>

      window.adlib.getLog( ' Mobile_Pos2 lazyload ', 'blue'  );
      insertBefore( '.entry-content h2:eq(0)', '<div id="Wrapper_Mobile_Pos2" style="min-height:328px;overflow: hidden;"><div id="Mobile_Pos2" class="Mobile_Pos2 mobileAd mitte1 mew-center-ad lazyload"></div></div>' );
      mylazyQueue.push( {
        Mobile_Pos2: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos2'}} );
        },
      } );

      window.adlib.getLog( ' Mobile_Pos3 lazyload ', 'blue'  );
      insertBefore( '.entry-content h2:eq(2)', '<div id="Mobile_Pos3" class="mobileAd mitte1 mew-center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Mobile_Pos3: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos3'}} );
        },
      } );

      window.adlib.getLog( ' Mobile_Pos4 lazyload ', 'blue'  );
      insertBefore( '.entry-content h2:eq(4)', '<div id="Mobile_Pos4" class="mobileAd mitte1 mew-center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Mobile_Pos4: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos4'}} );
        },
      } );

      window.adlib.getLog( ' Mobile_Pos5 lazyload ', 'blue'  );
      insertBefore( '.entry-content h2:eq(6)', '<div id="Mobile_Pos5" class="mobileAd mitte1 mew-center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Mobile_Pos5: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos5'}} );
        },
      } );

      window.adlib.getLog( ' Mobile_Pos6 lazyload ', 'blue'  );
      insertBefore( '.entry-content h2:eq(8)', '<div id="Mobile_Pos6" class="mobileAd mitte1 mew-center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Mobile_Pos6: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos6'}} );
        },
      } );

    } else {
      adQueue.push( {type: 'adCall', data: {adSlot: 'skyscraper'}} );

      window.adlib.getLog(' Content_1 lazyload ', 'blue' );
      insertBefore( '.entry-content h2:eq(0)', '<div id="Content_1" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Content_1: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_1'}} );
        },
      } );

      window.adlib.getLog( ' Content_2 lazyload ', 'blue' );
      insertBefore( '.entry-content h2:eq(2)', '<div id="Content_2" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Content_2: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_2'}} );
        },
      } );

      window.adlib.getLog( ' Content_3 lazyload ', 'blue' );
      insertBefore( '.entry-content h2:eq(4)', '<div id="Content_3" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Content_3: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_3'}} );
        },
      } );

      window.adlib.getLog( ' Content_4 lazyload ', 'blue' );
      insertBefore( '.entry-content h2:eq(6)', '<div id="Content_4" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Content_4: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_4'}} );
        },
      } );

      window.adlib.getLog( ' Content_5 lazyload ', 'blue' );
      insertBefore( '.entry-content h2:eq(8)', '<div id="Content_5" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Content_5: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_5'}} );
        },
      } );

      window.adlib.getLog( ' rectangle_1 lazyload ', 'blue' );
      insertAfter( '#sidebar article:eq(1)', '<div id="rectangle_1" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        rectangle_1: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'rectangle_1'}} );
        },
      } );

      window.adlib.getLog( ' rectangle_2 lazyload ', 'blue' );
      insertAfter( '#sidebar article:eq(4)', '<div id="rectangle_2" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        rectangle_2: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'rectangle_2'}} );
        },
      } );

    }

    window.adlib.getLog( ' recommendation_feed lazyload ', 'blue' );
    if ( pagePostType === 'utopia_news' ) {
      recommendationFeedAnchor = '#main_content #content-rating';
    }
    insertAfter( recommendationFeedAnchor, '<div id="performance_campaign" class="lazyload" data-expand="1200"></div><div id="recommendation_feed" class="lazyload" data-expand="1000"></div>' );
    mylazyQueue.push( {
      performance_campaign: function() {
        window.adQueue.push( {type: 'adCall', data: {adSlot: 'performance_campaign'}} );
      },
      recommendation_feed: function() {
        window.adQueue.push( {type: 'adCall', data: {adSlot: 'recommendation_feed'}} );
      },
    } );

  }
}
