import { insertAfter, insertBefore } from '../../lib/scripts/utilities';

export default function( window ) {
  if ( window.ads ) {
    let mylazyQueue = window.mylazyQueue || [];
    let adQueue = window.adQueue || [];

    // OutOfPage
    window.adlib.getLog( ' OutOfPage ', 'blue' );
    insertBefore( '#body-wrapper', '<div id="OutOfPage" style="position: absolute;"></div>' );
    // window.adQueue.push( {type: 'adCall', data: {adSlot: 'OutOfPage'}} );

    setTimeout(function() {
      window.adlib.getLog('OutOfPage - setTimeout 500', 'green');
      window.adQueue.push( { type: 'adCall', data: { adSlot: 'OutOfPage' } } );
    }, 500);

    if ( window.MobileMinWidth.matches === true ) {
      window.adlib.getLog( ' Mobile_Pos2 lazyload ', 'blue' );
      insertBefore( '#main_content>a:eq(2)', '<div id="Mobile_Pos2" class="mobileAd mitte1 mew-center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Mobile_Pos2: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos2'}} );
        },
      } );

      window.adlib.getLog( ' Mobile_Pos3 lazyload ', 'blue' );
      insertBefore( '#main_content>a:eq(4)', '<div id="Mobile_Pos3" class="mobileAd mitte1 mew-center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Mobile_Pos3: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Mobile_Pos3'}} );
        },
      } );


    } else {
      adQueue.push( {type: 'adCall', data: {adSlot: 'skyscraper'}} );

      window.adlib.getLog( ' Content_1 lazyload ', 'blue' );
      insertBefore( '#main_content>a:eq(2)', '<div id="Content_1" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Content_1: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_1'}} );
        },
      } );

      window.adlib.getLog( ' Content_2 lazyload ', 'blue' );
      insertBefore( '#main_content>a:eq(4)', '<div id="Content_2" class="contentAd mitte1 center-ad lazyload"></div>' );
      mylazyQueue.push( {
        Content_2: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'Content_2'}} );
        },
      } );

      window.adlib.getLog( ' rectangle_1 lazyload ', 'blue' );
      insertAfter( '#sidebar article:eq(1)', '<div id="rectangle_1" class="contentAd lazyload"></div>' );
      mylazyQueue.push( {
        rectangle_1: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'rectangle_1'}} );
        },
      } );

      window.adlib.getLog( ' rectangle_2 lazyload ', 'blue' );
      insertAfter( '#sidebar article:eq(3)', '<div id="rectangle_2" class="contentAd lazyload"></div>' );
      mylazyQueue.push( {
        rectangle_2: function() {
          window.adQueue.push( {type: 'adCall', data: {adSlot: 'rectangle_2'}} );
        },
      } );
    }

  }
}


